<template>
  <div class="wap-notice">
    <headerBar
      :title="$t('xi-tong-xiao-xi-0')"
      @back="back"
      :right-text="$t('quan-bu-yi-du')"
      @detail="allRead"
    ></headerBar>
    <List
      v-if="logList.length > 0"
      v-model="loading"
      :finished="finished"
      :finished-text="$t('common.noMore')"
      @load="onLoad"
    >
      <div
        class="notice"
        v-for="(item, i) in logList"
        :key="i"
        @click="toDetail(item)"
      >
        <div class="flex-center-between">
          <div class="title active">{{ item.Title }}</div>
          <div class="time">{{ item.CreatedAt }}</div>
        </div>
        <div class="content" v-html="item.Content"></div>
      </div>
    </List>
  </div>
</template>
<script>
import headerBar from '@/components/header'
import { List } from 'vant'
import { getNoticeList } from '@/api/index'
export default {
  components: {
    headerBar,
    List
  },
  data() {
    return {
      logList: [],
      page: {
        page: 1,
        limit: 10
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      totalPage: 1
    }
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      getNoticeList({
        current: this.page.page,
        pageSize: this.page.limit
      })
        .then((res) => {
          if (isAdd) {
            this.logList = this.logList.concat(res.data.Items)
          } else {
            this.logList = res.data.Items
          }
          this.totalPage = res.data.Pagination
            ? res.data.Pagination.totalPage
            : 1
          if (this.totalPage <= this.page.page) {
            this.finished = true
          }
          this.isInit = true
          this.loading = false
        })
        .catch((err) => {
          this.isInit = true
          this.loading = false
        })
    },
    allRead() {},
    toDetail(data) {
      sessionStorage.setItem('noticeDetail', JSON.stringify(data))
      this.$router.push({
        name: 'wapNoticeDetail'
      })
    }
  }
}
</script>